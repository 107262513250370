import React from 'react';
import Template from '@clearscore/shared.website-template';
import {
    CareersNav,
    PageHeader,
    ShadedBox,
    FullWidthImage,
    JobListings,
    GlassdoorFooter,
    GLASSDOOR_RATING,
} from '@clearscore/shared.website-careers-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useMarketConfig from '@clearscore-group/lib.hooks.use-market-config-deprecated';
import Text from '@clearscore/ui.rainbow.text';
import Spacer from '@clearscore/ui.rainbow.spacer';

import marketConfig from './lib/market-config';
import styles from './careers-jobs.module.css';
import { PAGE_NAMESPACE } from './lib/constants';

const CareersJobs = ({ images, appStoreConfig, cookiePolicyConfig, categories }) => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    const { jobListings: jobListingsConfig, lowerBox: lowerBoxConfig } = useMarketConfig(marketConfig);
    const jobListingsCopy = {
        ...t('workable'),
        glassdoorText: t('workable.glassdoorText', { rating: GLASSDOOR_RATING }),
    };

    return (
        <Template shouldHideNav appStoreConfig={appStoreConfig} cookiePolicyConfig={cookiePolicyConfig}>
            <CareersNav items={categories} theme={CareersNav.Theme.LIGHT} />
            <article data-layout="full-bleed">
                <Spacer all={{ vertical: Spacer.spacings.SUPER }} large={{ vertical: Spacer.spacings.JUMBO }}>
                    <PageHeader preheader={t('pageHeader.preheader')} header={t('pageHeader.header')} />
                </Spacer>
                <section className={styles.heroImage}>
                    <FullWidthImage image={images.hero} alt={t('pageHeader.imageAlt')} />
                </section>
                <Spacer all={{ vertical: Spacer.spacings.SUPER }}>
                    <JobListings language={jobListingsCopy} config={jobListingsConfig} />
                </Spacer>
                <section>
                    <ShadedBox
                        isFlipped
                        theme={ShadedBox.Theme.SAND}
                        headerSize={ShadedBox.HeaderSize.MASSIVE}
                        image={images.lowerBox}
                        alt={t('lowerBox.imageAlt')}
                        header={t('lowerBox.header')}
                    >
                        <Spacer all={{ vertical: Spacer.spacings.BIG }}>
                            <Text.Body1>{t('lowerBox.subheader')}</Text.Body1>
                        </Spacer>
                        <Text.Link href={lowerBoxConfig.ctaHref}>{t('lowerBox.ctaText')}</Text.Link>
                    </ShadedBox>
                </section>
                <Spacer all={{ vertical: Spacer.spacings.SUPER }}>
                    <GlassdoorFooter glassdoorText={jobListingsCopy.glassdoorText} />
                </Spacer>
            </article>
        </Template>
    );
};

CareersJobs.propTypes = {
    images: PropTypes.shape({
        hero: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
        lowerBox: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
    }).isRequired,
    appStoreConfig: PropTypes.shape({
        googlePlayUrl: PropTypes.string,
        appStoreUrl: PropTypes.string,
        appStoreLink: PropTypes.string,
        playStoreLink: PropTypes.string,
        appleStoreToggle: PropTypes.bool,
        googleStoreToggle: PropTypes.bool,
    }).isRequired,
    cookiePolicyConfig: PropTypes.shape({
        moreInfoLinkUrl: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
    }).isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            categoryNavPosition: PropTypes.number.isRequired,
            categoryUrl: PropTypes.string.isRequired,
            navTitle: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

CareersJobs.defaultProps = {};

export default CareersJobs;
