import { GB } from '@clearscore-group/lib.config.i18n';

export default {
    [GB]: {
        lowerBox: {
            ctaHref: 'mailto:clear-score@jobs.workablemail.com',
        },
        jobListings: {
            viewAllJobs: '/careers/jobs',
            maxJobsDisplayed: 5,
            isJobsPage: true,
        },
    },
};
